.ambilight-control {
  font-size: 12px;
  line-height: 12px;

  position: fixed;
  z-index: 100;
  top: 21px;
  left: 50%;

  transform: translateX(-50%);
  text-align: center;
  white-space: nowrap;

  color: #999;
}

.ambilight-control h1 {
  font-weight: normal;

  margin: 0 0 15px 0;
  padding: 0;

  color: #aaa;
}

.ambilight-control label {
  margin: 0;
  margin-right: 8px;
  padding: 0;
}

.ambilight-control input {
  font-size: 12px;
  line-height: 12px;

  margin: 0 2px 0 0;

  vertical-align: top;
}

.video-container {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
}

video {
  position: relative;
  z-index: 100;

  display: block;

  margin: 0;
  padding: 0;

  /* border: 10px solid black; */
  /* outline: 1px solid rgba(255, 255, 255, .1); */
  background: black;
}

.video-shadow {
  position: absolute;
}

.video-shadow_left {
  left: 0;

  border-radius: 100% 0 0 100%;
}

.video-shadow_right {
  right: 0;

  border-radius: 0 100% 100% 0;
}

.video-shadow_top {
  top: 0;

  border-radius: 100% 100% 0 0;
}

.video-shadow_bottom {
  bottom: 0;

  border-radius: 0 0 100% 100%;
}

.copyright {
  font-family: Arial;
  font-size: 12px;

  position: fixed;
  bottom: 5px;
  left: 50%;

  margin-left: -5em;
}

.hint {
  font-size: 16px;

  position: absolute;
  top: 50%;
  left: 50%;

  margin-top: 200px;
  margin-left: -320px;

  color: #aaa;
}

.copyright:link,
.copyright:visited,
.copyright:active,
.copyright:hover {
  color: #555;
}
